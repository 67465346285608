@use '../variables/viewports';
@use '../variables/sizes';

.page-setup {
    --app-page-background-color: var(--mdc-theme-surface, #fff);

    align-items: center;
    justify-content: center;

    .material {
        width: 100%;
        max-width: 408px;
        height: 100%;
    }

    .input {
        width: 100%;
    }

    @include viewports.s {
        .material {
            height: auto;
        }
    }
}
