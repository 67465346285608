@use '../variables/sizes';

.generic-entity-field-grid {
    display: flex;
    flex-flow: row wrap;
    padding: sizes.$orange 0 0 sizes.$aqua;

    .entity-text-field {
        display: flex;
        flex: 1 1 40%;
        align-items: center;
    }
}
