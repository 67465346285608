@use '../variables/viewports';
@use '../variables/sizes';

.page-preferences {
    .content {
        display: flex;
        flex-flow: row wrap;
        margin: sizes.$aqua 0 0 sizes.$aqua;
    }

    .card-link {
        display: flex;
        flex: 1 1 auto;
        margin-right: sizes.$aqua;
        margin-bottom: sizes.$aqua;
        width: 344px;
        text-decoration: none;
        color: unset;
        user-select: none;

        &:visited {
            color: unset;
        }

        .card {
            width: 100%;
        }

        .icon {
            --mdc-icon-size: #{sizes.$purple};
        }
    }
}
