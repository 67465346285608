@use '@material/elevation/elevation-theme' as elevation;
@use '../variables/z-axis';

.container-transform {
    &.card-to-page {
        --app-container-transform-shadow: #{elevation.elevation-box-shadow(0)};
        --app-container-transform-shadow-end: #{elevation.elevation-box-shadow(24)};
        --app-container-transform-zindex: #{z-axis.get('container-transform')};
    }

    &.fab-to-page {
        --app-container-transform-zindex: #{z-axis.get('container-transform')}; 
    }
}
