@use 'variables/colors';
@use 'variables/sizes';

body {
    --mdc-theme-primary: #{colors.$primary};
    --mdc-theme-secondary: #{colors.$secondary};
    --mdc-icon-font: 'Material Icons Two Tone';
    --mdc-typography-headline6-line-height: sizes.$teal;
    --mdc-typography-body2-line-height: 20px;
    --mdc-drawer-width: 305px;
}
