@mixin apply($color) {
    $icon-color-reset: #000;

    position: relative;
    color: $icon-color-reset;

    &::after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        mix-blend-mode: screen;
        background-color: #{$color};
        content: '';
    }
}
