@use '../mixins/two-tone-color';

mwc-icon {
    &:not([color]),
    &[color='font-on-white'] {
        @include two-tone-color.apply('var(--mdc-icon-color)');
    }

    &[color='white'] {
        filter: brightness(0) #{'invert()'};
    }

    &[color='black'] {
        filter: brightness(0);
    }
}
