@use '../variables/sizes';
@use '../variables/viewports';

.component-nav-drawer {
    $avatar-size: sizes.$purple;

    --mdc-typography-subtitle1-font-weight: 500;

    .navigation {
        .list-item {
            --mdc-icon-color: var(--mdc-theme-primary);
            margin: sizes.$gray sizes.$pink;
            border-radius: sizes.$gray;
            height: sizes.$lightblue;
        }

        .anchor {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    > .avatar {
        display: block;
        margin-bottom: 30px;
        border-radius: 50%;
        width: $avatar-size;
        height: $avatar-size;
    }

    > .divider {
        margin: 0;
        margin-top: sizes.$pink;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, .12);
    }

    &:not([open]) {
        --mdc-top-app-bar-width: 100%;
    }

    @include viewports.m {
        --mdc-top-app-bar-width: calc(100% - var(--mdc-drawer-width));
    }
}
