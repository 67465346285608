@use '../variables/sizes';

.page-generic-tabs {
    --app-page-background-color: var(--mdc-theme-surface, #fff);

    .content {
        display: flex;
        flex-flow: column;
        height: 100%;

        .tab-content-container {
            position: relative;
            height: 100%;
        }
    }
}
