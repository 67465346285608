@use '../variables/sizes';

.component-voice-memo-field {
    display: flex;
    align-items: center;

    .new,
    .label {
        flex: 1 1 auto;
    }

    .remove {
        flex: 0 0 auto;
    }
}
