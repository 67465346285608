@use '../variables/sizes';

.page-generic-detail {
    --app-page-background-color: var(--mdc-theme-surface, #fff);

    .content {
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        padding: sizes.$orange sizes.$aqua;
        padding-bottom: 100px;
        height: auto; // this prevents safari from verticall stretching the entity-text-field elements
        max-height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .entity-text-field {
            flex: 1 1 40%;
        }
    }
}
