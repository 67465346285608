@use './sizes';

@mixin all {
    @content;
}

@mixin s {
    @media (sizes.$viewport-s) {
        @content;
    }
}

@mixin m {
    @media (sizes.$viewport-m) {
        @content;
    }
}
