@use '../variables/z-axis';
@use '../variables/sizes';
@use '../variables/viewports';

.component-floating-action {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: z-axis.get('fab');
    margin: sizes.$aqua;

    &.speed-dial {
        --app-speed-dial-margin: #{sizes.$aqua};

        margin: 0;
    }

    &.hidden {
        display: none;
    }

    &.animate-show {
        animation-name: component-floating-action-animate;
        animation-duration: 150ms;
        animation-timing-function: ease-out;
        animation-direction: reverse;
        animation-fill-mode: forwards;
    }

    &.animate-hide {
        display: block;
        animation-name: component-floating-action-animate;
        animation-duration: 150ms;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
    }

    @include viewports.m {
        margin: sizes.$orange;

        &.speed-dial {
            --app-speed-dial-margin: #{sizes.$orange};
        }
    }
}

@keyframes component-floating-action-animate {
    from {
        transform: scale3d(1, 1, 1);
        transform-origin: center;
    }

    to {
        transform: scale3d(0, 0, 1);
        transform-origin: center;
    }
}
