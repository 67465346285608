html,
body {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  opacity: 85%;
  color: var(--mdc-theme-on-surface, #000);
}

[hidden] {
  display: none !important;
}

body {
  --mdc-theme-primary: #67b0b9;
  --mdc-theme-secondary: #edad8a;
  --mdc-icon-font: "Material Icons Two Tone";
  --mdc-typography-headline6-line-height: sizes.$teal;
  --mdc-typography-body2-line-height: 20px;
  --mdc-drawer-width: 305px;
}

@keyframes transition-stack-lower-scale {
  0% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes transition-stack-upper-scale {
  0% {
    transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes transition-stack-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
mwc-icon:not([color]), mwc-icon[color=font-on-white] {
  position: relative;
  color: #000;
}
mwc-icon:not([color])::after, mwc-icon[color=font-on-white]::after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  mix-blend-mode: screen;
  background-color: var(--mdc-icon-color);
  content: "";
}
mwc-icon[color=white] {
  filter: brightness(0) invert();
}
mwc-icon[color=black] {
  filter: brightness(0);
}

.container-transform.card-to-page {
  --app-container-transform-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --app-container-transform-shadow-end: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --app-container-transform-zindex: 1;
}
.container-transform.fab-to-page {
  --app-container-transform-zindex: 1;
}

a {
  text-decoration: none;
}

.component-nav-drawer {
  --mdc-typography-subtitle1-font-weight: 500;
}
.component-nav-drawer .navigation .list-item {
  --mdc-icon-color: var(--mdc-theme-primary);
  margin: 4px 8px;
  border-radius: 4px;
  height: 48px;
}
.component-nav-drawer .navigation .anchor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.component-nav-drawer > .avatar {
  display: block;
  margin-bottom: 30px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.component-nav-drawer > .divider {
  margin: 0;
  margin-top: 8px;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.component-nav-drawer:not([open]) {
  --mdc-top-app-bar-width: 100%;
}
@media (min-width: 1024px) {
  .component-nav-drawer {
    --mdc-top-app-bar-width: calc(100% - var(--mdc-drawer-width));
  }
}

.component-floating-action {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 6;
  margin: 16px;
}
.component-floating-action.speed-dial {
  --app-speed-dial-margin: 16px;
  margin: 0;
}
.component-floating-action.hidden {
  display: none;
}
.component-floating-action.animate-show {
  animation-name: component-floating-action-animate;
  animation-duration: 150ms;
  animation-timing-function: ease-out;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
.component-floating-action.animate-hide {
  display: block;
  animation-name: component-floating-action-animate;
  animation-duration: 150ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@media (min-width: 1024px) {
  .component-floating-action {
    margin: 32px;
  }
  .component-floating-action.speed-dial {
    --app-speed-dial-margin: 32px;
  }
}

@keyframes component-floating-action-animate {
  from {
    transform: scale3d(1, 1, 1);
    transform-origin: center;
  }
  to {
    transform: scale3d(0, 0, 1);
    transform-origin: center;
  }
}
.entity-text-field {
  margin: 0 16px 16px 0;
}
.entity-text-field .input {
  width: 100%;
}

.generic-entity-field-grid {
  display: flex;
  flex-flow: row wrap;
  padding: 32px 0 0 16px;
}
.generic-entity-field-grid .entity-text-field {
  display: flex;
  flex: 1 1 40%;
  align-items: center;
}

.component-voice-memo-field {
  display: flex;
  align-items: center;
}
.component-voice-memo-field .new,
.component-voice-memo-field .label {
  flex: 1 1 auto;
}
.component-voice-memo-field .remove {
  flex: 0 0 auto;
}

.page-setup {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
  align-items: center;
  justify-content: center;
}
.page-setup .material {
  width: 100%;
  max-width: 408px;
  height: 100%;
}
.page-setup .input {
  width: 100%;
}
@media (min-width: 408px) {
  .page-setup .material {
    height: auto;
  }
}

.page-login {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
  align-items: center;
  justify-content: center;
}
.page-login .material {
  width: 100%;
  max-width: 408px;
  height: 100%;
}
.page-login .input {
  width: 100%;
}
@media (min-width: 408px) {
  .page-login .material {
    height: auto;
  }
}

.page-sync-test {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
  align-items: center;
  justify-content: center;
}
.page-sync-test .material {
  width: 100%;
  max-width: 408px;
  height: 100%;
}
.page-sync-test .list {
  padding-left: 16px;
}
@media (min-width: 408px) {
  .page-sync-test .material {
    height: auto;
  }
}

.page-preferences .content {
  display: flex;
  flex-flow: row wrap;
  margin: 16px 0 0 16px;
}
.page-preferences .card-link {
  display: flex;
  flex: 1 1 auto;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 344px;
  text-decoration: none;
  color: unset;
  user-select: none;
}
.page-preferences .card-link:visited {
  color: unset;
}
.page-preferences .card-link .card {
  width: 100%;
}
.page-preferences .card-link .icon {
  --mdc-icon-size: 40px;
}

.page-permission-preferences {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-permission-preferences .content {
  padding: 16px;
}

.page-user-preferences {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-user-preferences .list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.page-user-detail-preferences {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}

.page-generic-tabs {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-generic-tabs .content {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.page-generic-tabs .content .tab-content-container {
  position: relative;
  height: 100%;
}

.page-generic-listing {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-generic-listing .content {
  box-sizing: border-box;
  display: block;
  padding-bottom: 100px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-generic-detail {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-generic-detail .content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  padding: 32px 16px;
  padding-bottom: 100px;
  height: auto;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.page-generic-detail .content .entity-text-field {
  flex: 1 1 40%;
}

.page-generic-edit {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}
.page-generic-edit .content {
  box-sizing: border-box;
  padding-bottom: 100px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.page-error .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.page-export-preferences {
  --app-page-background-color: var(--mdc-theme-surface, #fff);
}