$base: 8px;

$gray: $base / 2;
$pink: $base;
$aqua: $base * 2;
$teal: $base * 3;
$orange: $base * 4;
$purple: $base * 5;
$lightblue: $base * 6;
$brown: $base * 7;
$blue: $base * 8;
$violet: $base * 9;
$lightgreen: $base * 10;
$green: $base * 11;
$black: $base * 12;

$viewport-s: 'min-width: 408px';
$viewport-m: 'min-width: 1024px';
