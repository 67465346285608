html,
body {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    opacity: 85%;
    color: var(--mdc-theme-on-surface, #000);
}

[hidden] {
    display: none !important;
}
