@use '../variables/sizes';

.page-generic-listing {
    --app-page-background-color: var(--mdc-theme-surface, #fff);

    .content {
        box-sizing: border-box;
        display: block;
        padding-bottom: 100px;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}
