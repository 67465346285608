@keyframes transition-stack-lower-scale {
    0% {
        transform: scale3d(1, 1, 1);
    }

    100% {
        transform: scale3d(1.1, 1.1, 1);

    }
}

@keyframes transition-stack-upper-scale {
    0% {
        transform: scale3d(.8, .8, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);

    }
}

@keyframes transition-stack-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
